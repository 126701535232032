.c-1-color { background: rgb(216, 94, 216) !important; }
.c-2-color { background: rgb(72, 211, 134) !important; }
.c-3-color { background: rgb(0, 162, 255) !important; }
.c-4-color { background: rgb(226, 72, 72) !important; }
.c-5-color { background: rgb(255, 187, 0) !important; }


#sliderTest {
    height: 10px;
}

#sliderTest .noUi-connect {
    background: #c0392b;
}

#sliderTest .noUi-handle {
    height: 18px;
    width: 18px;
    top: -5px;
    right: -9px; /* half the width */
    border-radius: 9px;
}